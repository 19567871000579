export const TRANSLATIONS_EN = {
    // COMMON
    fieldRequired: "This field is required",
    fieldInvalid: "This field is invalid",
    fieldMaxLimit: "Less than {{limit}} characters are required",
    fieldMaxValueLimit: "Value should be less than {{limit}}",
    fieldMinValueLimit: "Value should be greater than {{limit}}",
    fieldEmailDuplicated: "Email duplicated",
    fieldConfirmPasswordCorrectly: "Confirm password correctly",

    success: "Success",
    warning: "Warning",
    error: "Error",

    noPermission: "You have no permission!",
    URLNotFound: "URL not found",
    badRequest: "Bad request",
    apiError: "API error",

    // HEADER
    lightningAddressWarning:
        "Please add at least one <a href='/profile?_tab=lightning'>Lightning Address</a> to receive payments",
    myWallet: "My wallet",
    myProfile: "My profile",
    withdrawalAccounts: "Withdrawal accounts",
    signOut: "Sign Out",

    // AUTH LAYOUT
    allRightReserved: "All rights reserved.",

    // LOGIN
    email: "Email",
    password: "Password",
    keepMeSignedIn: "Keep me signed in",
    signIn: "Sign In",
    forgetPassword: "Forget password",
    stillHaveNoAccount: "Still have no account?",
    signUp: "Sign Up",
    getCodeFromYourEmail: "Get code from your email",
    getCodeFromYourAuthenticatorApp: "Get code from your authenticator app",
    enterCodeFromYourEmail: "Enter code from your email",
    accountSuspendedError:
        "This account is suspended, please contact with support to <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",
    notAuthenticated: "You are not authenticated, please login again!",

    // REGISTER
    confirmPassword: "Confirm password",
    fullname: "Full name",
    alreadyHaveAnAccount: "Already have an account?",
    passwordRule: "8 characters and at least 1 special character(!@#$&*) are required",

    // FORGET PASSWORD
    code: "Code",
    sendCode: "Send code",
    resetPassword: "Reset password",
    resendCode: "Resend code",
    backToSignIn: "Back to Sign In",
    verificationCodeSentSuccessfully: "Verification code was sent successfully",

    // VERIFY ACCOUNT
    verify: "Verify",
    verify_email: "Verify email",
    verify_phone: "Verify phone",
    verifyDescriptionEmail:
        "To validate your account, we have dispatched a verification code to <b class='color-blue'>{{email}}</b>",
    verifyDescriptionPhone:
        "To validate your account, we have dispatched a second verification code to <b class='color-blue'>+{{phone}}</b>",
    verifySucceeded: "Your account has been successfully verified.",

    // SIDER
    dashboard: "Dashboard",
    transactions: "Transactions",
    withdrawals: "Withdrawals",
    shift: "Shift",
    companies: "Companies",
    users: "Users",
    pos: "POS",

    // DASHBOARD
    total: "Total",
    wallet: "Wallet",
    incoming: "Incoming",
    withdrawal: "Withdrawal",
    consumption: "Consumption",
    monthly: "Monthly",
    daily: "Daily",

    // INVOICES
    invoices: "Invoices",
    newInvoice: "New invoice",
    expireAt: "Expire at",
    localCurrency: "Local currency",
    amountReceived: "Amount received",
    paidAt: "Paid at",
    copyInvoiceUrl: "Copy invoice URL",
    refresh: "Refresh",
    noInvoice: "No invoice",
    payLightningInvoice: "Pay Lightning invoice",
    pay: "Pay",
    createInvoice: "Create invoice",
    invoiceDetail: "Invoice detail",
    invoiceIsExpired: "Invoice is expired",
    invoiceIsPaid: "Invoice is paid",
    awaitingPayment: "Awaiting payment",
    close: "Close",
    copy: "Copy",

    // TRANSACTIONS
    searchByDescription: "Search by description",
    selectPOSDevice: "Select POS device",
    all: "All",
    startDate: "Start date",
    finishDate: "Finish date",
    date: "Date",
    company: "Company",
    device: "Device",
    description: "Description",
    amount: "Amount",
    tip: "Tip",
    currency: "Currency",
    commission: "Commission",
    noTransaction: "No transaction",

    // WITHDRAWALS
    withdrawalToBitcoin: "Withdrawal to Bitcoin",
    withdrawalToLightningAddress: "Withdrawal to Lightning Address",
    withdrawalWithLightningInvoice: "Withdrawal with Lightning invoice",
    lightningAddress: "Lightning Address",
    selectLightningAddress: "Select Lightning Address",
    withdrawalAmountLimitTip:
        "We remind you that for your security, the first withdrawal in a new account cannot exceed {{firstAmount}} SATs. The second withdrawal in this new account can be made after 24 hours, and for a maximum daily amount of {{dailyAmount}} SATs.",
    lightningWithdrawalInvoiceTip:
        "This withdrawal request will be reviewed and approved by admin, it will take some time, so please set enough invoice expiration when you create an invoice.",
    request: "Request",
    toBankAccount: "To bank account",
    toBitcoinAccount: "To bitcoin account",
    requestWithdrawal: "Request withdrawal",
    account: "Account",
    status: "Status",
    manager: "Manager",
    bank: "Bank",
    bitcoin: "Bitcoin",
    noWithdrawal: "No withdrawal",
    requestWithdrawalToBank: "Request withdrawal to bank",
    bankAccount: "Bank account",
    selectBankAccount: "Select bank account",
    shortDescriptionAboutWithdrawal: "Short description about the withdrawal",
    cancel: "Cancel",
    create: "Create",
    requestWithdrawalToBitcoin: "Request withdrawal to bitcoin",
    bitcoinAccount: "Bitcoin account",
    selectBitcoinAccount: "Select bitcoin account",
    balanceNotEnoughError: "Your wallet balance is not enough to complete payment",
    withdrawalToLightningAccount: "Withdrawal to lightning account",
    invoiceUrl: "Invoice URL",
    expiry: "Expiry",
    seconds: "Seconds",
    proceedWithdrawal: "Proceed withdrawal",
    withdrawalStatus_initiated: "Initiated",
    withdrawalStatus_in_review: "In review",
    withdrawalStatus_in_process: "In process",
    withdrawalStatus_finished: "Finished",
    withdrawalStatus_rejected: "Rejected",
    withdrawalStatusDescription_initiated: "Withdrawal request is created, awaiting for the review by manager",
    withdrawalStatusDescription_in_review: "Withdrawal request is under the review by manager",
    withdrawalStatusDescription_in_process: "Withdrawal request is in process",
    withdrawalStatusDescription_finished: "Withdrawal process is finished",
    withdrawalStatusDescription_rejected: "Withdrawal request is rejected",

    // SHIFT
    name: "Name",
    startedAt: "Started at",
    finishedAt: "Finished at",
    deviceParingCode: "Device pairing code",
    noShift: "No shift",

    // COMPANIES
    confirmDeleteCompany: "Are you sure to delete this company?",
    delete: "Delete",
    companyName: "Company name",
    createdAt: "Created at",
    edit: "Edit",
    noCompanies: "No companies",
    updateCompany: "Update company",
    createCompany: "Create company",
    saveChanges: "Save changes",

    // USERS
    confirmDeleteUser: "Are you sure to delete this user?",
    noUsers: "No users",
    updateUser: "Update user",
    createUser: "Create user",
    noCompanyAssigned: "No company assigned",
    reset: "Reset",
    suspended: "Suspended",
    yes: "Yes",
    assignedToNumberOfCompanies: "Assigned to {{number}} companies",
    selectCompanies: "Select companies",

    // DEVICES
    confirmDeleteDevice: "Are you sure to delete this device?",
    POSDevices: "POS Devices",
    paringCode: "Pairing code",
    user: "User",
    printPurchase: "Print purchase",
    receiveTip: "Receive tip",
    noPOSDevices: "No POS devices",
    updateDevice: "Update device",
    createDevice: "Create device",
    noUserAssigned: "No user assigned",
    marketType: "Market type",

    // PROFILE
    generalInformation: "General information",
    birthday: "Birthday",
    phone: "Phone",
    country: "Country",
    avatar: "Profile image",
    selectFile: "Select file",
    save: "Save",
    security: "Security",
    "2fa": "Two factor authentication",
    "2faDescription":
        "Two-factor authentication (2FA) is a security method used to protect your www.rodpay.com account. The main objective of two-factor authentication is to add an additional layer of security beyond the traditional password. By using email as the second factor of authentication, even if someone manages to obtain your password, they won't be able to access your account without having access to your email to obtain the code.",
    allow2fa: "Activate Two-factor authentication (2FA)",
    setup2FASuccess: "Setting Two-factor authentication (2FA) up succeeded",
    confirmDisable2fa: "Are you sure to disable Two-factor authentication (2FA)?",
    confirm: "Confirm",
    disable2fa: "Disable Two-factor authentication (2FA)",
    inputCode: "Input code",
    "2faSetupGuide":
        "Please scan below QR code using your authenticator app such as Google Authenticator, and input code.",
    customerInformation: "Customer information",
    nationalID: "National ID",
    companyDUNS: "Company DUNS",
    selectFileByClickOrDragFileHereToUpload: "Select file by click or drag file here to upload",
    upload: "Upload",
    nationalIDNeededDescription: "To withdrawal more than 1,000,000 SATs, you need to verify your National ID",
    companyDUNSNeededDescription: "To withdrawal more than 10,000,000 SATs, you need to verify your Company DUNS",
    nationalIDIsUploaded: "National ID is uploaded",
    companyDUNSIsUploaded: "Company DUNS is uploaded",
    inReview: "In review",
    approved: "Approved",
    rejected: "Rejected",

    // INTEGRATION
    integration: "Integration",
    apiKey: "API Key",
    apiKeyDescription:
        "To integrate RodPay into your own WooCommerce store, you need to generate an API key and config payment gateway settings using it.",
    generateApiKey: "Generate API Key",
    apiKeyGeneratedTip: "This API Key is visible only once, please save it in a secure place.",
    copyToClipboard: "Copy to clipboard",
    apiKeySaved: "API Key saved",
    copiedToClipboard: "API Key copied to clipboard",
    apiKeySavedTip: "You have already saved your API Key, you can generate a new one or revoke it.",
    generateNewApiKey: "Generate new API Key",
    revokeApiKey: "Revoke API Key",
    confirmRefreshApiKey: "Are you sure to refresh API Key?",
    confirmRevokeApiKey: "Are you sure to revoke API Key?",
    revoke: "Revoke",

    // USER LOG
    actionLogs: "Action logs",
    action: "Action",
    model: "Model",
    detail: "Detail",
    user_action_create: "Create",
    user_action_update: "Update",
    user_action_delete: "Delete",
    user_action_soft_delete: "Delete",
    user_action_restore: "Restore",
    user_action_reset_password: "Reset password",
    user_action_tfa_enable: "Enable 2FA",
    user_action_tfa_disable: "Disable 2FA",
    user_action_update_profile: "Update profile",
    user_action_invoice_paid: "Invoice paid",
    user_action_invoice_expired: "Invoice expired",
    user_action_withdrawal_in_review: "Review withdrawal",
    user_action_withdrawal_in_process: "Proceed withdrawal",
    user_action_withdrawal_finished: "Finish withdrawal",
    user_action_withdrawal_rejected: "Reject withdrawal",
    user_action_api_key_generated: "API Key generated",
    user_action_api_key_revoked: "API Key revoked",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "Bank Accounts",
    bitcoinAccounts: "Bitcoin accounts",
    confirmDeleteBankAccount: "Are you sure to delete this bank account?",
    noBankAccountAdded: "No bank account added",
    confirmDeleteBitcoinAccount: "Are you sure to delete this bitcoin account?",
    noBitcoinAccountAdded: "No bitcoin account added",
    updateBankAccountInfo: "Update bank account info",
    addNewBankAccount: "Add new bank account",
    nickname: "Nickname",
    bankName: "Bank name",
    swiftCode: "Swift code",
    accountNumber: "Account number",
    accountName: "Account name",
    address: "Address",
    zipCode: "ZIP code",
    updateBitcoinAccountInfo: "Update bitcoin account info",
    addNewBitcoinAccount: "Add new bitcoin account",
    lightningAddresses: "Lightning Addresses",
    addNewLightningAddress: "Add new Lightning Address",
    confirmDeleteLightningAddress: "Are you sure to delete this Lightning Address?",
    noLightningAddressAdded: "No Lightning Address added",
    isPrimary: "Is primary",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded:
        "Withdrawal amount limitation exceeded. First withdrawal amount should be less than 5,000 SATs.",
    secondWithdrawalTimeLimitExceeded:
        "Withdrawal time limitation exceeded. Second withdrawal can be made after 24 hours from the first withdrawal.",
    oneDayWithdrawalAmountLimitExceeded:
        "Withdrawal amount limitation exceeded. Daily withdrawal amount should be less than 1,000,000 SATs.",
    withdrawalKYCNationalIDRequired:
        "Your total withdrawal amount exceeds the limit (1,000,000 SATs), please verify your National ID to continue.",
    withdrawalKYCCompanyDUNSRequired:
        "Your total withdrawal amount exceeds the limit (10,000,000 SATs), please verify your Company DUNS to continue.",
    lightningInvoice: "Lightning invoice",

    // PAYMENT PAGE
    waitingForPayment: "Waiting for payment...",
    invoiceQRCodeTip: "Scan the QR code to pay the invoice or click to copy invoice URL.",
    invoiceURLCopied: "Invoice URL copied to clipboard.",
    invoiceExpired: "Invoice expired!",
    paymentSucceeded: "Payment succeeded!",
    redirecting: "Redirecting...",
};
