/* eslint-disable max-len */
export const TRANSLATIONS_FR = {
    // COMMON
    fieldRequired: "Ce champ est obligatoire",
    fieldInvalid: "Ce champ est invalide",
    fieldMaxLimit: "Moins de {{limit}} caractères sont requis",
    fieldMaxValueLimit: "La valeur doit être inférieure à {{limit}}",
    fieldMinValueLimit: "La valeur doit être supérieure à {{limit}}",
    fieldEmailDuplicated: "E-mail dupliqué",
    fieldConfirmPasswordCorrectly: "Confirmer correctement le mot de passe",

    success: "Succès",
    warning: "Avertissement",
    error: "Erreur",

    noPermission: "Vous n'avez aucune autorisation !",
    URLNotFound: "URL introuvable",
    badRequest: "Mauvaise demande",
    apiError: "Erreur API",

    // HEADER
    lightningAddressWarning:
        "Veuillez ajouter au moins une <a href='/profile?_tab=lightning'>adresse Lightning</a> pour recevoir les paiements",
    myWallet: "Mon portefeuille",
    myProfile: "Mon profil",
    withdrawalAccounts: "Comptes de retrait",
    signOut: "Se déconnecter",

    // AUTH LAYOUT
    allRightReserved: "Tous droits réservés.",

    // LOGIN
    email: "E-mail",
    password: "Mot de passe",
    keepMeSignedIn: "Gardez-moi connecté",
    signIn: "S'identifier",
    forgetPassword: "Mot de passe oublié",
    stillHaveNoAccount: "Vous n'avez toujours pas de compte ?",
    signUp: "S'inscrire",
    getCodeFromYourEmail: "Obtenez le code de votre e-mail",
    getCodeFromYourAuthenticatorApp: "Obtenez le code de votre application d'authentification",
    enterCodeFromYourEmail: "Entrez le code de votre email",
    accountSuspendedError:
        "Ce compte est suspendu, veuillez contacter l'assistance à <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",
    notAuthenticated: "Vous n'êtes pas authentifié, veuillez vous reconnecter !",

    // REGISTER
    confirmPassword: "Confirmez le mot de passe",
    fullname: "Nom et prénom",
    alreadyHaveAnAccount: "Vous avez déjà un compte?",
    passwordRule: "8 caractères et au moins 1 caractère spécial(!@#$&*) sont requis",

    // FORGET PASSWORD
    code: "Code",
    sendCode: "Envoyer le code",
    resetPassword: "Réinitialiser le mot de passe",
    resendCode: "Renvoyer le code",
    backToSignIn: "Retour à la connexion",
    verificationCodeSentSuccessfully: "Le code de vérification a été envoyé avec succès",

    // VERIFY ACCOUNT
    verify: "Vérifier",
    verify_email: "Vérifier les courriels",
    verify_phone: "Vérifier le téléphone",
    verifyDescriptionEmail:
        "Pour valider votre compte, nous avons envoyé un code de vérification à <b class='color-blue'>{{email}}</b>",
    verifyDescriptionPhone:
        "Pour valider votre compte, nous avons envoyé un deuxième code de vérification à <b class='color-blue'>+{{phone}}</b>",
    verifySucceeded: "Votre compte a été vérifié avec succès.",

    // SIDER
    dashboard: "Tableau de bord",
    transactions: "Transactions",
    withdrawals: "Retraits",
    shift: "Changement",
    companies: "Entreprises",
    users: "Utilisateurs",
    pos: "POS",

    // DASHBOARD
    total: "Total",
    wallet: "Portefeuille",
    incoming: "Entrant",
    withdrawal: "Retrait",
    consumption: "Consommation",
    monthly: "Mensuel",
    daily: "Quotidien",

    // INVOICES
    invoices: "Factures",
    newInvoice: "Nouvelle facture",
    expireAt: "Expire à",
    localCurrency: "Monnaie locale",
    amountReceived: "Montant reçu",
    paidAt: "Payé à",
    copyInvoiceUrl: "Copier l'URL de la facture",
    refresh: "Rafraîchir",
    noInvoice: "Pas de facture",
    payLightningInvoice: "Payer la facture Lightning",
    pay: "Payer",
    createInvoice: "Créer une facture",
    invoiceDetail: "Détails de la facture",
    invoiceIsExpired: "La facture est expirée",
    invoiceIsPaid: "La facture est payée",
    awaitingPayment: "En attente de paiement",
    close: "Fermer",
    copy: "Copie",

    // TRANSACTIONS
    searchByDescription: "Rechercher par descriptif",
    selectPOSDevice: "Sélectionnez l'appareil PDV",
    all: "Tout",
    startDate: "Date de début",
    finishDate: "Date de fin",
    date: "Date",
    company: "Entreprise",
    device: "Appareil",
    description: "Description",
    amount: "Montant",
    tip: "Conseil",
    currency: "Monnaie",
    commission: "Commission",
    noTransaction: "Aucune transaction",

    // WITHDRAWALS
    withdrawalToBitcoin: "Retrait vers Bitcoin",
    withdrawalToLightningAddress: "Retrait à l'adresse Lightning",
    withdrawalWithLightningInvoice: "Retrait avec facture Lightning",
    lightningAddress: "Adresse Lightning",
    selectLightningAddress: "Sélectionnez l'adresse Lightning",
    withdrawalAmountLimitTip:
        "Nous vous rappelons que pour votre sécurité, le premier retrait sur un nouveau compte ne peut excéder {{firstAmount}} SAT. Le deuxième retrait sur ce nouveau compte peut être effectué après 24 heures et pour un montant quotidien maximum de {{dailyAmount}} SAT.",
    lightningWithdrawalInvoiceTip:
        "Cette demande de retrait sera examinée et approuvée par l'administrateur, cela prendra un certain temps, veuillez donc définir suffisamment d'expiration de facture lorsque vous créez une facture.",
    request: "Demande",
    toBankAccount: "Vers un compte bancaire",
    toBitcoinAccount: "Vers un compte bitcoin",
    requestWithdrawal: "Demander un retrait",
    account: "Compte",
    status: "Statut",
    manager: "Directeur",
    bank: "Banque",
    bitcoin: "Bitcoin",
    noWithdrawal: "Pas de retrait",
    requestWithdrawalToBank: "Demander un retrait à la banque",
    bankAccount: "compte bancaire",
    selectBankAccount: "Sélectionnez le compte bancaire",
    shortDescriptionAboutWithdrawal: "Brève description du retrait",
    cancel: "Annuler",
    create: "Créer",
    requestWithdrawalToBitcoin: "Demander un retrait en bitcoin",
    bitcoinAccount: "Compte Bitcoin",
    selectBitcoinAccount: "Sélectionnez un compte bitcoin",
    balanceNotEnoughError: "Le solde de votre portefeuille n'est pas suffisant pour effectuer le paiement",
    withdrawalToLightningAccount: "Retrait sur compte éclair",
    invoiceUrl: "URL de la facture",
    expiry: "Expiration",
    seconds: "Seconds",
    proceedWithdrawal: "Procéder au retrait",
    withdrawalStatus_initiated: "Initié",
    withdrawalStatus_in_review: "En revue",
    withdrawalStatus_in_process: "En cours",
    withdrawalStatus_finished: "Fini",
    withdrawalStatus_rejected: "Rejeté",
    withdrawalStatusDescription_initiated:
        "La demande de retrait est créée, en attente de l'examen par le gestionnaire",
    withdrawalStatusDescription_in_review: "La demande de retrait est en cours d'examen par le responsable",
    withdrawalStatusDescription_in_process: "La demande de retrait est en cours",
    withdrawalStatusDescription_finished: "Le processus de retrait est terminé",
    withdrawalStatusDescription_rejected: "La demande de retrait est rejetée",

    // SHIFT
    name: "Nom",
    startedAt: "Commencé à",
    finishedAt: "Terminé à",
    deviceParingCode: "Code d'appariement de l'appareil",
    noShift: "Pas de décalage",

    // COMPANIES
    confirmDeleteCompany: "Voulez-vous vraiment supprimer cette entreprise ?",
    delete: "Supprimer",
    companyName: "Nom de l'entreprise",
    createdAt: "Créé à",
    edit: "Modifier",
    noCompanies: "Aucune entreprise",
    updateCompany: "Mettre à jour l'entreprise",
    createCompany: "Créer une entreprise",
    saveChanges: "Sauvegarder les modifications",

    // USERS
    confirmDeleteUser: "Voulez-vous vraiment supprimer cet utilisateur ?",
    noUsers: "Aucun utilisateur",
    updateUser: "Mettre à jour l'utilisateur",
    createUser: "Créer un utilisateur",
    noCompanyAssigned: "Aucune entreprise assignée",
    reset: "Réinitialiser",
    suspended: "Suspendu",
    yes: "Oui",
    assignedToNumberOfCompanies: "Attribué à {{number}} entreprises",
    selectCompanies: "Sélectionnez les entreprises",

    // DEVICES
    confirmDeleteDevice: "Voulez-vous vraiment supprimer cet appareil ?",
    POSDevices: "Appareils PDV",
    paringCode: "Code d'association",
    user: "Utilisateur",
    printPurchase: "Achat d'impression",
    receiveTip: "Recevoir un pourboire",
    noPOSDevices: "Aucun appareil PDV",
    updateDevice: "Mettre à jour l'appareil",
    createDevice: "Créer un appareil",
    noUserAssigned: "Aucun utilisateur affecté",
    marketType: "Type de marché",

    // PROFILE
    generalInformation: "Informations générales",
    birthday: "Anniversaire",
    phone: "Téléphone",
    country: "Pays",
    avatar: "Photo de profil",
    selectFile: "Choisir le dossier",
    save: "Sauvegarder",
    security: "Sécurité",
    "2fa": "Authentification à deux facteurs",
    "2faDescription":
        "L'authentification à deux facteurs (2FA) est une méthode de sécurité utilisée pour protéger votre compte www.rodpay.com. L’objectif principal de l’authentification à deux facteurs est d’ajouter une couche de sécurité supplémentaire au-delà du mot de passe traditionnel. En utilisant le courrier électronique comme deuxième facteur d'authentification, même si quelqu'un parvient à obtenir votre mot de passe, il ne pourra pas accéder à votre compte sans avoir accès à votre courrier électronique pour obtenir le code.",
    allow2fa: "Activer l'authentification à deux facteurs (2FA)",
    setup2FASuccess: "La configuration de l'authentification à deux facteurs (2FA) a réussi",
    confirmDisable2fa: "Êtes-vous sûr de désactiver l'authentification à deux facteurs (2FA) ?",
    confirm: "Confirmer",
    disable2fa: "Désactiver l'authentification à deux facteurs (2FA)",
    inputCode: "Code d'entrée",
    "2faSetupGuide":
        "Veuillez scanner le code QR ci-dessous à l'aide de votre application d'authentification telle que Google Authenticator et saisir le code.",
    customerInformation: "Informations client",
    nationalID: "Carte d'identité",
    companyDUNS: "Société DUNS",
    selectFileByClickOrDragFileHereToUpload:
        "Sélectionnez le fichier en cliquant ou en faisant glisser le fichier ici pour le télécharger",
    upload: "Télécharger",
    nationalIDNeededDescription:
        "Pour retirer plus de 1 000 000 de SAT, vous devez vérifier votre carte d'identité nationale",
    companyDUNSNeededDescription:
        "Pour retirer plus de 10 000 000 SAT, vous devez vérifier le DUNS de votre entreprise.",
    nationalIDIsUploaded: "La pièce d'identité nationale est téléchargée",
    companyDUNSIsUploaded: "La société DUNS est téléchargée",
    inReview: "En revue",
    approved: "Approuvé",
    rejected: "Rejeté",

    // INTEGRATION
    integration: "L'intégration",
    apiKey: "Clé API",
    apiKeyDescription:
        "Pour intégrer RodPay dans votre propre boutique WooCommerce, vous devez générer une clé API et configurer les paramètres de la passerelle de paiement à l'aide de celle-ci.",
    generateApiKey: "Générer une clé API",
    apiKeyGeneratedTip:
        "Cette clé API n'est visible qu'une seule fois, veuillez la sauvegarder dans un endroit sécurisé.",
    copyToClipboard: "Copier dans le presse-papier",
    apiKeySaved: "Clé API enregistrée",
    copiedToClipboard: "Clé API copiée dans le presse-papiers",
    apiKeySavedTip: "Vous avez déjà enregistré votre clé API, vous pouvez en générer une nouvelle ou la révoquer.",
    generateNewApiKey: "Générer une nouvelle clé API",
    revokeApiKey: "Révoquer la clé API",
    confirmRefreshApiKey: "Êtes-vous sûr d'actualiser la clé API ?",
    confirmRevokeApiKey: "Êtes-vous sûr de révoquer la clé API ?",
    revoke: "Révoquer",

    // USER LOG
    actionLogs: "Journaux d'actions",
    action: "Action",
    model: "Modèle",
    detail: "Détail",
    user_action_create: "Créer",
    user_action_update: "Mise à jour",
    user_action_delete: "Supprimer",
    user_action_soft_delete: "Supprimer",
    user_action_restore: "Restaurer",
    user_action_reset_password: "Réinitialiser le mot de passe",
    user_action_tfa_enable: "Activer 2FA",
    user_action_tfa_disable: "Désactiver 2FA",
    user_action_update_profile: "Mettre à jour le profil",
    user_action_invoice_paid: "Facture payee",
    user_action_invoice_expired: "Facture expirée",
    user_action_withdrawal_in_review: "Examen du retrait",
    user_action_withdrawal_in_process: "Procéder au retrait",
    user_action_withdrawal_finished: "Terminer le retrait",
    user_action_withdrawal_rejected: "Refuser le retrait",
    user_action_api_key_generated: "Clé API générée",
    user_action_api_key_revoked: "Clé API révoquée",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "Comptes bancaires",
    bitcoinAccounts: "Comptes Bitcoin",
    confirmDeleteBankAccount: "Voulez-vous vraiment supprimer ce compte bancaire ?",
    noBankAccountAdded: "Aucun compte bancaire ajouté",
    confirmDeleteBitcoinAccount: "Êtes-vous sûr de vouloir supprimer ce compte bitcoin ?",
    noBitcoinAccountAdded: "Aucun compte bitcoin ajouté",
    updateBankAccountInfo: "Mettre à jour les informations du compte bancaire",
    addNewBankAccount: "Ajouter un nouveau compte bancaire",
    nickname: "Surnom",
    bankName: "Nom de banque",
    swiftCode: "Code rapide",
    accountNumber: "Numéro de compte",
    accountName: "Nom du compte",
    address: "Adresse",
    zipCode: "Code postal",
    updateBitcoinAccountInfo: "Mettre à jour les informations du compte bitcoin",
    addNewBitcoinAccount: "Ajouter un nouveau compte bitcoin",
    lightningAddresses: "Adresses Lightning",
    addNewLightningAddress: "Ajouter une nouvelle adresse Lightning",
    confirmDeleteLightningAddress: "Êtes-vous sûr de vouloir supprimer cette adresse Lightning ?",
    noLightningAddressAdded: "Aucune adresse Lightning ajoutée",
    isPrimary: "Est primaire",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded:
        "Limite du montant de retrait dépassée. Le montant du premier retrait doit être inférieur à 5 000 SAT.",
    secondWithdrawalTimeLimitExceeded:
        "Délai de retrait dépassé. Le deuxième retrait peut être effectué 24 heures après le premier retrait.",
    oneDayWithdrawalAmountLimitExceeded:
        "Limite du montant de retrait dépassée. Le montant du retrait quotidien doit être inférieur à 1 000 000 SAT.",
    withdrawalKYCNationalIDRequired:
        "Le montant total de votre retrait dépasse la limite (1 000 000 SAT), veuillez vérifier votre carte d'identité nationale pour continuer.",
    withdrawalKYCCompanyDUNSRequired:
        "Le montant total de votre retrait dépasse la limite (10 000 000 SAT), veuillez vérifier le DUNS de votre entreprise pour continuer.",
    lightningInvoice: "Facture Lightning",

    // PAYMENT PAGE
    waitingForPayment: "En attente de paiement...",
    invoiceQRCodeTip: "Scannez le code QR pour payer la facture ou cliquez pour copier l'URL de la facture.",
    invoiceURLCopied: "URL de la facture copiée dans le presse-papiers.",
    invoiceExpired: "Facture expirée !",
    paymentSucceeded: "Paiement réussi !",
    redirecting: "Redirection...",
};
