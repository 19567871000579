export const TRANSLATIONS_RU = {
    // COMMON
    fieldRequired: "Это поле обязательно к заполнению",
    fieldInvalid: "Это поле недействительно",
    fieldMaxLimit: "Требуется менее {{limit}} символов",
    fieldMaxValueLimit: "Значение должно быть меньше {{limit}}",
    fieldMinValueLimit: "Значение должно быть больше {{limit}}",
    fieldEmailDuplicated: "Электронная почта дублируется",
    fieldConfirmPasswordCorrectly: "Подтвердите пароль правильно",

    success: "Успех",
    warning: "Предупреждение",
    error: "Ошибка",

    noPermission: "У вас нет разрешения!",
    URLNotFound: "URL-адрес не найден",
    badRequest: "Неверный запрос",
    apiError: "Ошибка API",

    // HEADER
    lightningAddressWarning:
        "Пожалуйста, добавьте хотя бы один <a href='/profile?_tab=lightning'>адрес Lightning</a> для получения платежей",
    myWallet: "Мой бумажник",
    myProfile: "Мой профайл",
    withdrawalAccounts: "Счета вывода",
    signOut: "Выход",

    // AUTH LAYOUT
    allRightReserved: "Все права защищены.",

    // LOGIN
    email: "Электронная почта",
    password: "Пароль",
    keepMeSignedIn: "Держите меня в системе",
    signIn: "Войти",
    forgetPassword: "Забыть пароль",
    stillHaveNoAccount: "Все еще нет аккаунта?",
    signUp: "Зарегистрироваться",
    getCodeFromYourEmail: "Получите код с вашей электронной почты",
    getCodeFromYourAuthenticatorApp: "Получите код из приложения для аутентификации",
    enterCodeFromYourEmail: "Введите код с вашей электронной почты",
    accountSuspendedError:
        "Эта учетная запись заблокирована. Свяжитесь со службой поддержки по адресу <a href='mailto:{{email}}' target='_blank'>{{email}}</a>.",
    notAuthenticated: "Вы не авторизованы, пожалуйста, войдите снова!",

    // REGISTER
    confirmPassword: "Подтвердите пароль",
    fullname: "Полное имя",
    alreadyHaveAnAccount: "У вас уже есть аккаунт?",
    passwordRule: "Требуется 8 символов и хотя бы 1 специальный символ(!@#$&*).",

    // FORGET PASSWORD
    code: "Код",
    sendCode: "Отправить код",
    resetPassword: "Сброс пароля",
    resendCode: "Отправить код еще раз",
    backToSignIn: "Вернуться к входу",
    verificationCodeSentSuccessfully: "Код подтверждения успешно отправлен",

    // VERIFY ACCOUNT
    verify: "Проверять",
    verify_email: "Подтвердить Email",
    verify_phone: "Подтвердить телефон",
    verifyDescriptionEmail:
        "Для проверки вашей учетной записи мы отправили код подтверждения на адрес <b class='color-blue'>{{email}}</b>.",
    verifyDescriptionPhone:
        "Для проверки вашей учетной записи мы отправили второй код подтверждения на номер <b class='color-blue'>+{{phone}}</b>.",
    verifySucceeded: "Ваша учетная запись успешно подтверждена.",

    // SIDER
    dashboard: "Панель приборов",
    transactions: "Транзакции",
    withdrawals: "Снятие средств",
    shift: "Сдвиг",
    companies: "Компании",
    users: "Пользователи",
    pos: "POS",

    // DASHBOARD
    total: "Общий",
    wallet: "Кошелек",
    incoming: "Входящий",
    withdrawal: "Снятие",
    consumption: "Потребление",
    monthly: "Ежемесячно",
    daily: "Ежедневно",

    // INVOICES
    invoices: "Счета",
    newInvoice: "Новый счет",
    expireAt: "Срок действия в",
    localCurrency: "Местная валюта",
    amountReceived: "Полученная сумма",
    paidAt: "Оплачено в",
    copyInvoiceUrl: "Скопировать URL счета",
    refresh: "Обновить",
    noInvoice: "Нет счета",
    payLightningInvoice: "Оплатить счет Lightning",
    pay: "Платить",
    createInvoice: "Создать счет",
    invoiceDetail: "Сведения о счете",
    invoiceIsExpired: "Срок действия счета истек",
    invoiceIsPaid: "Счет оплачен",
    awaitingPayment: "Ожидание оплаты",
    close: "Закрывать",
    copy: "Копировать",

    // TRANSACTIONS
    searchByDescription: "Поиск по описанию",
    selectPOSDevice: "Выберите POS-устройство",
    all: "Все",
    startDate: "Дата начала",
    finishDate: "Дата окончания",
    date: "Дата",
    company: "Компания",
    device: "Устройство",
    description: "Описание",
    amount: "Количество",
    tip: "Кончик",
    currency: "Валюта",
    commission: "комиссия",
    noTransaction: "Нет транзакции",

    // WITHDRAWALS
    withdrawalToBitcoin: "Вывод на Bitcoin",
    withdrawalToLightningAddress: "Вывод на адрес Lightning",
    withdrawalWithLightningInvoice: "Вывод средств с помощью счета Lightning",
    lightningAddress: "Lightning Адрес",
    selectLightningAddress: "Выберите адрес Lightning",
    withdrawalAmountLimitTip:
        "Напоминаем, что в целях вашей безопасности первый вывод средств с новой учетной записи не может превышать {{firstAmount}} SAT. Второй вывод средств с этого нового счета можно сделать через 24 часа и на максимальную ежедневную сумму в размере {{dailyAmount}} SAT.",
    lightningWithdrawalInvoiceTip:
        "Этот запрос на вывод средств будет рассмотрен и одобрен администратором. Это займет некоторое время, поэтому при создании счета установите достаточный срок действия счета.",
    request: "Запрос",
    toBankAccount: "На банковский счет",
    toBitcoinAccount: "На биткойн-счет",
    requestWithdrawal: "Запросить вывод",
    account: "Счет",
    status: "Cтатус",
    manager: "Менеджер",
    bank: "Банк",
    bitcoin: "Биткойн",
    noWithdrawal: "Нет вывода",
    requestWithdrawalToBank: "Запросить вывод в банк",
    bankAccount: "банковский счет",
    selectBankAccount: "Выберите банковский счет",
    shortDescriptionAboutWithdrawal: "Краткое описание вывода",
    cancel: "Отмена",
    create: "Создавать",
    requestWithdrawalToBitcoin: "Запросить вывод в биткойн",
    bitcoinAccount: "Биткойн-счет",
    selectBitcoinAccount: "Выберите биткойн-счет",
    balanceNotEnoughError: "Баланса вашего кошелька недостаточно для завершения платежа",
    withdrawalToLightningAccount: "Вывод на лайтнинг аккаунт",
    invoiceUrl: "URL счета",
    expiry: "Срок действия",
    seconds: "Секунды",
    proceedWithdrawal: "Приступить к снятию",
    withdrawalStatus_initiated: "Инициировано",
    withdrawalStatus_in_review: "В обзоре",
    withdrawalStatus_in_process: "В процессе",
    withdrawalStatus_finished: "Законченный",
    withdrawalStatus_rejected: "Отклоненный",
    withdrawalStatusDescription_initiated: "Создан запрос на вывод средств, ожидающий рассмотрения менеджером",
    withdrawalStatusDescription_in_review: "Заявка на вывод находится на рассмотрении менеджера",
    withdrawalStatusDescription_in_process: "Запрос на вывод средств находится в обработке",
    withdrawalStatusDescription_finished: "Процесс вывода завершен",
    withdrawalStatusDescription_rejected: "Запрос на вывод средств отклонен",

    // SHIFT
    name: "Имя",
    startedAt: "Началось в",
    finishedAt: "Закончено в",
    deviceParingCode: "Код сопряжения устройства",
    noShift: "Без смены",

    // COMPANIES
    confirmDeleteCompany: "Вы уверены, что хотите удалить эту компанию?",
    delete: "Удалить",
    companyName: "Название компании",
    createdAt: "Создан в",
    edit: "Редактировать",
    noCompanies: "Нет компаний",
    updateCompany: "Обновить компанию",
    createCompany: "Создать компанию",
    saveChanges: "Сохранить изменения",

    // USERS
    confirmDeleteUser: "Вы уверены, что хотите удалить этого пользователя?",
    noUsers: "Нет пользователей",
    updateUser: "Обновить пользователя",
    createUser: "Создать пользователя",
    noCompanyAssigned: "Компания не назначена",
    reset: "Перезагрузить",
    suspended: "Приостановленный",
    yes: "Да",
    assignedToNumberOfCompanies: "Назначено {{number}} компаниям",
    selectCompanies: "Выберите компании",

    // DEVICES
    confirmDeleteDevice: "Вы уверены, что хотите удалить это устройство?",
    POSDevices: "POS-устройства",
    paringCode: "Код сопряжения",
    user: "Пользователь",
    printPurchase: "Распечатать покупку",
    receiveTip: "Получить чаевые",
    noPOSDevices: "Нет POS-устройств",
    updateDevice: "Обновить устройство",
    createDevice: "Создать устройство",
    noUserAssigned: "Пользователь не назначен",
    marketType: "Тип рынка",

    // PROFILE
    generalInformation: "Общая информация",
    birthday: "День рождения",
    phone: "Телефон",
    country: "Страна",
    avatar: "Изображение профиля",
    selectFile: "Выберите файл",
    save: "Сохранять",
    security: "Безопасность",
    "2fa": "Двухфакторная аутентификация",
    "2faDescription":
        "Двухфакторная аутентификация (2FA) — это метод безопасности, используемый для защиты вашей учетной записи www.rodpay.com. Основная цель двухфакторной аутентификации — добавить дополнительный уровень безопасности помимо традиционного пароля. Используя электронную почту в качестве второго фактора аутентификации, даже если кому-то удастся получить ваш пароль, он не сможет получить доступ к вашей учетной записи, не имея доступа к вашей электронной почте для получения кода.",
    allow2fa: "Активируйте двухфакторную аутентификацию (2FA)",
    setup2FASuccess: "Настройка двухфакторной аутентификации (2FA) выполнена успешно.",
    confirmDisable2fa: "Вы уверены, что отключили двухфакторную аутентификацию (2FA)?",
    confirm: "Подтверждать",
    disable2fa: "Отключить двухфакторную аутентификацию (2FA)",
    inputCode: "Введите код",
    "2faSetupGuide":
        "Пожалуйста, отсканируйте приведенный ниже QR-код, используя приложение для аутентификации, например Google Authenticator, и введите код.",
    customerInformation: "Информация для клиентов",
    nationalID: "Национальный идентификатор",
    companyDUNS: "Компания ДУНС",
    selectFileByClickOrDragFileHereToUpload: "Выберите файл, щелкнув мышью, или перетащите его сюда, чтобы загрузить.",
    upload: "Загрузить",
    nationalIDNeededDescription:
        "Чтобы снять более 1 000 000 SAT, вам необходимо подтвердить свое национальное удостоверение личности.",
    companyDUNSNeededDescription: "Для вывода более 10 000 000 SAT вам необходимо подтвердить свою компанию DUNS.",
    nationalIDIsUploaded: "Национальный идентификатор загружен.",
    companyDUNSIsUploaded: "Компания DUNS загружено",
    inReview: "В обзоре",
    approved: "Одобренный",
    rejected: "Отклоненный",

    // INTEGRATION
    integration: "Интеграция",
    apiKey: "API-ключ",
    apiKeyDescription:
        "Чтобы интегрировать RodPay в ваш собственный магазин WooCommerce, вам необходимо сгенерировать ключ API и настроить с его помощью параметры платежного шлюза.",
    generateApiKey: "Создать ключ API",
    apiKeyGeneratedTip: "Этот ключ API виден только один раз, сохраните его в надежном месте.",
    copyToClipboard: "Скопировать в буфер обмена",
    apiKeySaved: "Ключ API сохранен.",
    copiedToClipboard: "Ключ API скопирован в буфер обмена.",
    apiKeySavedTip: "Вы уже сохранили свой ключ API, вы можете создать новый или отозвать его.",
    generateNewApiKey: "Создать новый ключ API",
    revokeApiKey: "Отозвать ключ API",
    confirmRefreshApiKey: "Вы уверены обновить ключ API?",
    confirmRevokeApiKey: "Вы уверены, что отозвать ключ API?",
    revoke: "Отозвать",

    // USER LOG
    actionLogs: "Журналы действий",
    action: "Действие",
    model: "Модель",
    detail: "Деталь",
    user_action_create: "Создавать",
    user_action_update: "Обновлять",
    user_action_delete: "Удалить",
    user_action_soft_delete: "Удалить",
    user_action_restore: "Восстановить",
    user_action_reset_password: "Сброс пароля",
    user_action_tfa_enable: "Включить 2FA",
    user_action_tfa_disable: "Отключить 2FA",
    user_action_update_profile: "Обновить профиль",
    user_action_invoice_paid: "Счет оплачен",
    user_action_invoice_expired: "Срок действия счета истек",
    user_action_withdrawal_in_review: "Обзор отзыва",
    user_action_withdrawal_in_process: "Продолжить вывод",
    user_action_withdrawal_finished: "Завершить вывод",
    user_action_withdrawal_rejected: "Отклонить вывод средств",
    user_action_api_key_generated: "Ключ API сгенерирован",
    user_action_api_key_revoked: "Ключ API отозван",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "Банковские счета",
    bitcoinAccounts: "Биткойн-счета",
    confirmDeleteBankAccount: "Вы уверены, что хотите удалить этот банковский счет?",
    noBankAccountAdded: "Банковский счет не добавлен",
    confirmDeleteBitcoinAccount: "Вы уверены, что хотите удалить этот биткойн-аккаунт?",
    noBitcoinAccountAdded: "Биткойн-аккаунт не добавлен",
    updateBankAccountInfo: "Обновить информацию о банковском счете",
    addNewBankAccount: "Добавить новый банковский счет",
    nickname: "Псевдоним",
    bankName: "название банка",
    swiftCode: "Свифт-код",
    accountNumber: "Номер счета",
    accountName: "Имя учетной записи",
    address: "Адрес",
    zipCode: "Почтовый индекс",
    updateBitcoinAccountInfo: "Обновить информацию о биткойн-счете",
    addNewBitcoinAccount: "Добавить новый биткойн-счет",
    lightningAddresses: "Lightning Адреса",
    addNewLightningAddress: "Добавить новый адрес Lightning",
    confirmDeleteLightningAddress: "Вы уверены, что хотите удалить этот адрес Lightning?",
    noLightningAddressAdded: "Адрес Lightning не добавлен",
    isPrimary: "Является первичным",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded:
        "Превышено ограничение суммы вывода. Сумма первого вывода должна составлять менее 5000 SAT.",
    secondWithdrawalTimeLimitExceeded:
        "Превышено ограничение времени вывода средств. Второй вывод можно осуществить через 24 часа после первого вывода.",
    oneDayWithdrawalAmountLimitExceeded:
        "Превышено ограничение суммы вывода. Сумма ежедневного вывода не должна превышать 1 000 000 SAT.",
    withdrawalKYCNationalIDRequired:
        "Общая сумма вывода превышает лимит (1 000 000 SAT), подтвердите свое национальное удостоверение личности, чтобы продолжить.",
    withdrawalKYCCompanyDUNSRequired:
        "Общая сумма вывода превышает лимит (10 000 000 SAT). Чтобы продолжить, подтвердите свою компанию DUNS.",
    lightningInvoice: "Lightning счет-фактура",

    // PAYMENT PAGE
    waitingForPayment: "Ожидается платеж...",
    invoiceQRCodeTip: "Отсканируйте QR-код, чтобы оплатить счет, или щелкните, чтобы скопировать URL-адрес счета.",
    invoiceURLCopied: "URL-адрес счета-фактуры скопирован в буфер обмена.",
    invoiceExpired: "Срок действия счета истек!",
    paymentSucceeded: "Оплата прошла успешно!",
    redirecting: "Перенаправление...",
};
