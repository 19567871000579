import { combineReducers } from "redux";

import theme from "./theme";
import auth from "./auth";
import invoice from "./invoice";
import transaction from "./transaction";
import shift from "./shift";
import company from "./company";
import user from "./user";
import pos from "./pos";

export default combineReducers({
    theme,
    auth,
    invoice,
    transaction,
    shift,
    company,
    user,
    pos,
});
