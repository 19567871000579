export const TRANSLATIONS_VI = {
    // COMMON
    fieldRequired: "Trường này là bắt buộc",
    fieldInvalid: "Trường này không hợp lệ",
    fieldMaxLimit: "Cần ít hơn {{limit}} ký tự",
    fieldMaxValueLimit: "Giá trị phải nhỏ hơn {{limit}}",
    fieldMinValueLimit: "Giá trị phải lớn hơn {{limit}}",
    fieldEmailDuplicated: "Email trùng lặp",
    fieldConfirmPasswordCorrectly: "Xác nhận mật khẩu chính xác",

    success: "Thành công",
    warning: "Cảnh báo",
    error: "Lỗi",

    noPermission: "Bạn không có quyền!",
    URLNotFound: "Không tìm thấy URL",
    badRequest: "Yêu cầu xấu",
    apiError: "lỗi API",

    // HEADER
    lightningAddressWarning:
        "Vui lòng thêm ít nhất một <a href='/profile?_tab=lightning'>Địa chỉ Lightning</a> để nhận thanh toán",
    myWallet: "Ví của tôi",
    myProfile: "Thông tin của tôi",
    withdrawalAccounts: "Tài khoản rút tiền",
    signOut: "Đăng xuất",

    // AUTH LAYOUT
    allRightReserved: "Đã đăng ký Bản quyền.",

    // LOGIN
    email: "E-mail",
    password: "Mật khẩu",
    keepMeSignedIn: "Giữ cho tôi đăng nhập",
    signIn: "Đăng nhập",
    forgetPassword: "Quên mật khẩu",
    stillHaveNoAccount: "Vẫn chưa có tài khoản?",
    signUp: "Đăng ký",
    getCodeFromYourEmail: "Nhận mã từ email của bạn",
    getCodeFromYourAuthenticatorApp: "Nhận mã từ ứng dụng xác thực của bạn",
    enterCodeFromYourEmail: "Nhập mã từ email của bạn",
    accountSuspendedError:
        "Tài khoản này đã bị tạm ngưng, vui lòng liên hệ với bộ phận hỗ trợ tới <a href='mailto:{{email}}' target='_blank'>{{email}</a>",
    notAuthenticated: "Bạn chưa được xác thực, vui lòng đăng nhập lại!",

    // REGISTER
    confirmPassword: "Xác nhận mật khẩu",
    fullname: "Họ và tên",
    alreadyHaveAnAccount: "Bạn co săn san để tạo một tai khoản?",
    passwordRule: "Cần có 8 ký tự và ít nhất 1 ký tự đặc biệt(!@#$&*)",

    // FORGET PASSWORD
    code: "Mã số",
    sendCode: "Gửi mã",
    resetPassword: "Đặt lại mật khẩu",
    resendCode: "Mã gửi lại",
    backToSignIn: "Quay lại Đăng nhập",
    verificationCodeSentSuccessfully: "Mã xác minh đã được gửi thành công",

    // VERIFY ACCOUNT
    verify: "Xác minh",
    verify_email: "Xác nhận Email",
    verify_phone: "Xác minh điện thoại",
    verifyDescriptionEmail:
        "Để xác thực tài khoản của bạn, chúng tôi đã gửi mã xác minh tới <b class='color-blue'>{{email}</b>",
    verifyDescriptionPhone:
        "Để xác thực tài khoản của bạn, chúng tôi đã gửi mã xác minh thứ hai tới <b class='color-blue'>+{{phone}</b>",
    verifySucceeded: "Tài khoản của bạn đã được xác minh thành công.",

    // SIDER
    dashboard: "Bảng điều khiển",
    transactions: "Giao dịch",
    withdrawals: "Rút tiền",
    shift: "Sự thay đổi",
    companies: "Các công ty",
    users: "Người dùng",
    pos: "POS",

    // DASHBOARD
    total: "Tổng cộng",
    wallet: "Cái ví",
    incoming: "Mới đến",
    withdrawal: "Rút tiền",
    consumption: "Sự tiêu thụ",
    monthly: "Hàng tháng",
    daily: "Hằng ngày",

    // INVOICES
    invoices: "Hóa đơn",
    newInvoice: "Hóa đơn mới",
    expireAt: "Hết hạn tại",
    localCurrency: "Nội tệ",
    amountReceived: "Số tiền nhận được",
    paidAt: "Thanh toán tại",
    copyInvoiceUrl: "Sao chép URL hóa đơn",
    refresh: "Làm cho khỏe lại",
    noInvoice: "Không có hóa đơn",
    payLightningInvoice: "Thanh toán hóa đơn chớp nhoáng",
    pay: "Chi trả",
    createInvoice: "Tạo hóa đơn",
    invoiceDetail: "Chi tiết hóa đơn",
    invoiceIsExpired: "Hóa đơn hết hạn",
    invoiceIsPaid: "Hóa đơn được thanh toán",
    awaitingPayment: "Đang chờ thanh toán",
    close: "Đóng",
    copy: "Sao chép",

    // TRANSACTIONS
    searchByDescription: "Tìm kiếm theo mô tả",
    selectPOSDevice: "Chọn thiết bị POS",
    all: "Tất cả",
    startDate: "Ngày bắt đầu",
    finishDate: "Ngày kết thúc",
    date: "Ngày",
    company: "Công ty",
    device: "Thiết bị",
    description: "Sự miêu tả",
    amount: "Số lượng",
    tip: "Mẹo",
    currency: "Tiền tệ",
    commission: "Nhiệm vụ",
    noTransaction: "Không có giao dịch",

    // WITHDRAWALS
    withdrawalToBitcoin: "Rút tiền về Bitcoin",
    withdrawalToLightningAddress: "Rút tiền về địa chỉ Lightning",
    withdrawalWithLightningInvoice: "Rút tiền bằng hóa đơn Lightning",
    lightningAddress: "Địa chỉ Lightning",
    selectLightningAddress: "Chọn địa chỉ Lightning",
    withdrawalAmountLimitTip:
        "Chúng tôi xin nhắc bạn rằng để bảo mật cho bạn, lần rút tiền đầu tiên trong tài khoản mới không được vượt quá {{firstAmount}} SAT. Lần rút tiền thứ hai trong tài khoản mới này có thể được thực hiện sau 24 giờ và với số lượng SAT tối đa hàng ngày là {{dailyAmount}}.",
    lightningWithdrawalInvoiceTip:
        "Yêu cầu rút tiền này sẽ được quản trị viên xem xét và phê duyệt, sẽ mất một chút thời gian, vì vậy vui lòng đặt đủ thời hạn hóa đơn khi bạn tạo hóa đơn.",
    request: "Lời yêu cầu",
    toBankAccount: "Đến tài khoản ngân hàng",
    toBitcoinAccount: "Đến tài khoản bitcoin",
    requestWithdrawal: "Yêu cầu rút tiền",
    account: "Tài khoản",
    status: "Trạng thái",
    manager: "Giám đốc",
    bank: "Ngân hàng",
    bitcoin: "Bitcoin",
    noWithdrawal: "Không rút tiền",
    requestWithdrawalToBank: "Yêu cầu rút tiền về ngân hàng",
    bankAccount: "Tài khoản ngân hàng",
    selectBankAccount: "Chọn tài khoản ngân hàng",
    shortDescriptionAboutWithdrawal: "Mô tả ngắn về việc rút tiền",
    cancel: "Hủy bỏ",
    create: "Tạo nên",
    requestWithdrawalToBitcoin: "Yêu cầu rút về bitcoin",
    bitcoinAccount: "Tài khoản bitcoin",
    selectBitcoinAccount: "Chọn tài khoản bitcoin",
    balanceNotEnoughError: "Số dư trong ví của bạn không đủ để hoàn tất thanh toán",
    withdrawalToLightningAccount: "Rút tiền về tài khoản sét",
    invoiceUrl: "URL hóa đơn",
    expiry: "Hết hạn",
    seconds: "Giây",
    proceedWithdrawal: "Tiến hành rút tiền",
    withdrawalStatus_initiated: "Bắt đầu",
    withdrawalStatus_in_review: "Đang xem xét",
    withdrawalStatus_in_process: "Đang tiến hành",
    withdrawalStatus_finished: "Hoàn thành",
    withdrawalStatus_rejected: "Vật bị loại bỏ",
    withdrawalStatusDescription_initiated: "Yêu cầu rút tiền được tạo, đang chờ người quản lý xem xét",
    withdrawalStatusDescription_in_review: "Yêu cầu rút tiền đang được xem xét bởi người quản lý",
    withdrawalStatusDescription_in_process: "Yêu cầu rút tiền đang được xử lý",
    withdrawalStatusDescription_finished: "Quá trình rút tiền đã hoàn tất",
    withdrawalStatusDescription_rejected: "Yêu cầu rút tiền bị từ chối",

    // SHIFT
    name: "Tên",
    startedAt: "Bắt đầu lúc",
    finishedAt: "Kết thúc lúc",
    deviceParingCode: "Mã ghép nối thiết bị",
    noShift: "Không có sự thay đổi",

    // COMPANIES
    confirmDeleteCompany: "Bạn có chắc chắn xóa công ty này?",
    delete: "Xóa bỏ",
    companyName: "Tên công ty",
    createdAt: "Tạo tại",
    edit: "Biên tập",
    noCompanies: "Không có công ty",
    updateCompany: "Cập nhật công ty",
    createCompany: "Tạo công ty",
    saveChanges: "Lưu thay đổi",

    // USERS
    confirmDeleteUser: "Bạn có chắc chắn xóa người dùng này không?",
    noUsers: "Không có người dùng",
    updateUser: "Cập nhật người dùng",
    createUser: "Tạo người dùng",
    noCompanyAssigned: "Không có công ty nào được chỉ định",
    reset: "Cài lại",
    suspended: "Cấm",
    yes: "Đúng",
    assignedToNumberOfCompanies: "Đã giao cho {{number}} công ty",
    selectCompanies: "Chọn công ty",

    // DEVICES
    confirmDeleteDevice: "Bạn có chắc chắn xóa thiết bị này không?",
    POSDevices: "Thiết bị POS",
    paringCode: "Mã ghép nối",
    user: "Người dùng",
    printPurchase: "In mua hàng",
    receiveTip: "Nhận tiền boa",
    noPOSDevices: "Không có thiết bị POS",
    updateDevice: "Cập nhật thiết bị",
    createDevice: "Tạo thiết bị",
    noUserAssigned: "Không có người dùng nào được chỉ định",
    marketType: "Loại thị trường",

    // PROFILE
    generalInformation: "Thông tin chung",
    birthday: "Sinh nhật",
    phone: "Điện thoại",
    country: "Quốc gia",
    avatar: "Hình ảnh hồ sơ cá nhân",
    selectFile: "Chọn tập tin",
    save: "Cứu",
    security: "Bảo vệ",
    "2fa": "Xác thực hai yếu tố",
    "2faDescription":
        "Xác thực hai yếu tố (2FA) là phương thức bảo mật được sử dụng để bảo vệ tài khoản www.rodpay.com của bạn. Mục tiêu chính của xác thực hai yếu tố là thêm một lớp bảo mật bổ sung ngoài mật khẩu truyền thống. Bằng cách sử dụng email làm yếu tố xác thực thứ hai, ngay cả khi ai đó lấy được mật khẩu của bạn, họ sẽ không thể truy cập vào tài khoản của bạn nếu không có quyền truy cập vào email của bạn để lấy mã.",
    allow2fa: "Kích hoạt xác thực hai yếu tố (2FA)",
    setup2FASuccess: "Thiết lập xác thực hai yếu tố (2FA) thành công",
    confirmDisable2fa: "Bạn có chắc chắn tắt xác thực hai yếu tố (2FA) không?",
    confirm: "Xác nhận",
    disable2fa: "Tắt xác thực hai yếu tố (2FA)",
    inputCode: "Mã đầu vào",
    "2faSetupGuide": "Vui lòng quét mã QR bên dưới bằng ứng dụng xác thực của bạn như Google Authenticator và nhập mã.",
    customerInformation: "Thông tin khách hàng",
    nationalID: "CMND",
    companyDUNS: "Công ty DUNS",
    selectFileByClickOrDragFileHereToUpload: "Chọn tệp bằng cách nhấp hoặc kéo tệp vào đây để tải lên",
    upload: "Tải lên",
    nationalIDNeededDescription: "Để rút hơn 1.000.000 SAT, bạn cần xác minh CMND của mình",
    companyDUNSNeededDescription: "Để rút hơn 10.000.000 SAT, bạn cần xác minh DUNS Công ty của mình",
    nationalIDIsUploaded: "CMND đã được tải lên",
    companyDUNSIsUploaded: "Công ty DUNS được tải lên",
    inReview: "Đang xem xét",
    approved: "Tán thành",
    rejected: "Vật bị loại bỏ",

    // INTEGRATION
    integration: "Hội nhập",
    apiKey: "Mã API",
    apiKeyDescription:
        "Để tích hợp RodPay vào cửa hàng WooC Commerce của riêng bạn, bạn cần tạo khóa API và định cấu hình cài đặt cổng thanh toán bằng cách sử dụng nó.",
    generateApiKey: "Tạo khóa API",
    apiKeyGeneratedTip: "Khóa API này chỉ hiển thị một lần, vui lòng lưu nó ở nơi an toàn.",
    copyToClipboard: "Sao chép vào clipboard",
    apiKeySaved: "Đã lưu khóa API",
    copiedToClipboard: "Đã sao chép khóa API vào bảng nhớ tạm",
    apiKeySavedTip: "Bạn đã lưu Khóa API của mình, bạn có thể tạo một khóa mới hoặc thu hồi nó.",
    generateNewApiKey: "Tạo khóa API mới",
    revokeApiKey: "Thu hồi khóa API",
    confirmRefreshApiKey: "Bạn có chắc chắn làm mới Khóa API không?",
    confirmRevokeApiKey: "Bạn có chắc chắn thu hồi Khóa API không?",
    revoke: "Thu hồi",

    // USER LOG
    actionLogs: "Nhật ký hành động",
    action: "Hoạt động",
    model: "Người mẫu",
    detail: "Chi tiết",
    user_action_create: "Tạo nên",
    user_action_update: "Cập nhật",
    user_action_delete: "Xóa bỏ",
    user_action_soft_delete: "Xóa bỏ",
    user_action_restore: "Khôi phục",
    user_action_reset_password: "Đặt lại mật khẩu",
    user_action_tfa_enable: "Kích hoạt 2FA",
    user_action_tfa_disable: "Vô hiệu hóa 2FA",
    user_action_update_profile: "Cập nhật hồ sơ",
    user_action_invoice_paid: "Hóa đơn thanh toán",
    user_action_invoice_expired: "Hóa đơn đã hết hạn",
    user_action_withdrawal_in_review: "Xem lại việc rút tiền",
    user_action_withdrawal_in_process: "Tiến hành rút tiền",
    user_action_withdrawal_finished: "Hoàn tất việc rút tiền",
    user_action_withdrawal_rejected: "Từ chối rút tiền",
    user_action_api_key_generated: "Đã tạo khóa API",
    user_action_api_key_revoked: "Khóa API đã bị thu hồi",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "Tài khoản ngân hàng",
    bitcoinAccounts: "Tài khoản bitcoin",
    confirmDeleteBankAccount: "Bạn có chắc chắn xóa tài khoản ngân hàng này không?",
    noBankAccountAdded: "Không có tài khoản ngân hàng nào được thêm vào",
    confirmDeleteBitcoinAccount: "Bạn có chắc chắn xóa tài khoản bitcoin này không?",
    noBitcoinAccountAdded: "Không có tài khoản bitcoin nào được thêm vào",
    updateBankAccountInfo: "Cập nhật thông tin tài khoản ngân hàng",
    addNewBankAccount: "Thêm tài khoản ngân hàng mới",
    nickname: "Tên nick",
    bankName: "Tên ngân hàng",
    swiftCode: "Mã nhanh",
    accountNumber: "Số tài khoản",
    accountName: "Tên tài khoản",
    address: "Địa chỉ",
    zipCode: "Mã Bưu Chính",
    updateBitcoinAccountInfo: "Cập nhật thông tin tài khoản bitcoin",
    addNewBitcoinAccount: "Thêm tài khoản bitcoin mới",
    lightningAddresses: "Địa chỉ Lightning",
    addNewLightningAddress: "Thêm địa chỉ Lightning mới",
    confirmDeleteLightningAddress: "Bạn có chắc chắn muốn xóa Địa chỉ Lightning này không?",
    noLightningAddressAdded: "Không có địa chỉ Lightning nào được thêm vào",
    isPrimary: "Là chính",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded: "Đã vượt quá giới hạn số tiền rút. Số tiền rút đầu tiên phải ít hơn 5.000 SAT.",
    secondWithdrawalTimeLimitExceeded:
        "Đã vượt quá giới hạn thời gian rút tiền. Lần rút tiền thứ hai có thể được thực hiện sau 24 giờ kể từ lần rút tiền đầu tiên.",
    oneDayWithdrawalAmountLimitExceeded:
        "Đã vượt quá giới hạn số tiền rút. Số tiền rút hàng ngày phải ít hơn 1.000.000 SAT.",
    withdrawalKYCNationalIDRequired:
        "Tổng số tiền rút của bạn vượt quá giới hạn (1.000.000 SAT), vui lòng xác minh ID quốc gia của bạn để tiếp tục.",
    withdrawalKYCCompanyDUNSRequired:
        "Tổng số tiền rút của bạn vượt quá giới hạn (10.000.000 SAT), vui lòng xác minh DUNS Công ty của bạn để tiếp tục.",
    lightningInvoice: "Hóa đơn Lightning",

    // PAYMENT PAGE
    waitingForPayment: "Đang chờ thanh toán...",
    invoiceQRCodeTip: "Quét mã QR để thanh toán hóa đơn hoặc nhấp để sao chép URL hóa đơn.",
    invoiceURLCopied: "Đã sao chép URL hóa đơn vào khay nhớ tạm.",
    invoiceExpired: "Hóa đơn đã hết hạn!",
    paymentSucceeded: "Thanh toán thành công!",
    redirecting: "Đang chuyển hướng...",
};
